<template>
  <div class="horizontalVideo_box">
    <div class="phead-left" @click.stop="$router.go(-1)">
      <svg-icon icon-class="userBack" />
    </div>
    <Loading v-if="isLoading" />
    <div class="horizontalVideo_warp" v-else>
      <div class="h_video">
        <div class="videoAdv" v-if="advsList.length > 0 && !(userInfo.isVip && videoInfo.vidStatus.hasPaid) && isShowAdv">
          <AdvVideoSwiper :advList="advsList" v-if="advsList.length" :hasPagination="false" class="adv" :key="advsList[0].cover" />
          <div class="timer_close" v-if="userInfo.isVip || videoInfo.vidStatus.hasPaid">
            <div v-if="timer > 0">
              {{ timer }}秒后
              <span @click="isShowAdv = false">关闭</span>
            </div>
            <div v-else>
              <span @click="isShowAdv = false">关闭</span>
            </div>
          </div>
          <div class="timer_close" v-else>
            <div v-if="timer > 0">
              {{ timer }}秒后关闭
              <span @click="$router.push('/memberCentre?t=vip')">VIP跳过广告</span>
            </div>
            <div v-else>
              <span @click="isShowAdv = false">关闭</span>
            </div>
          </div>
        </div>
        <HorIzontalScreen v-else-if="videoInfo.id" :videoInfo="videoInfo" :key="videoInfo.id" />
      </div>
      <div class="content">
        <van-tabs
          v-model="activeName"
          class="video_tab"
          background="#ffffff"
          title-active-color="#ff678f"
          title-inactive-color="#666666"
          animated
          :swipeable="false"
          @disabled="onClickTab"
        >
          <van-tab title="简介" name="introduction">
            <div class="content_all">
              <Introduction :videoInfo="videoInfo" :key="videoInfo.id" :getVideoInfo="getVideoInfo" />
            </div>
          </van-tab>
          <van-tab title="评论" name="discuss" :badge="commenTotal" :disabled="!userInfo.isVip">
            <div class="content_all">
              <Discuss :videoId="videoInfo.id" :key="videoInfo.id" :commentLis="commentLis" :addCommenTotal="addCommenTotal" />
            </div>
          </van-tab>
        </van-tabs>
        <div class="menu">
          <div class="menu_item" @click="collecte">
            <svg-icon :icon-class="videoInfo.vidStatus && videoInfo.vidStatus.hasCollected ? 'tip_like_active' : 'tip_like'" />
            <span>{{ (videoInfo.forwardCount || '点赞') | watchCount }}</span>
          </div>
          <div class="menu_item" @click="openShare">
            <svg-icon icon-class="share_video" />
            <span>赚钱</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tab, Tabs, Toast } from 'vant';
import Introduction from '@/views/movies/horizontalVideo/Introduction';
import Loading from '@/components/Loading';
import Discuss from '@/views/movies/horizontalVideo/discuss';
import HorIzontalScreen from '@/components/Video/HorIzontalScreen';
import { getVidInfo } from '@/api/video';
import { queryCommentList } from '@/api/user';
import { getBeijinTime } from '@/utils';
import { mapGetters } from 'vuex';
import { AdType, getAdItem } from '@/utils/getConfig';
import AdvVideoSwiper from '@/components/AdvVideoSwiper';
import { careCollectVideo } from '@/api/user';

export default {
  components: {
    AdvVideoSwiper,
    HorIzontalScreen,
    Discuss,
    Introduction,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    Loading,
  },
  data() {
    return {
      activeName: '',
      isLoading: true,
      videoInfo: {
        publisher: {},
        vidStatus: {},
      }, //视频信息
      commentLis: [], //评论列表
      commenTotal: 0, //评论条数
      advsList: [],
      timer: 0,
      isShowAdv: true,
      countdowner: null,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  created() {
    // this.getRandomAdv();
    // this.countdown();
    this.getVideoInfo();
  },
  watch: {
    videoInfo(new1, old) {
      this.isShowAdv = true;
      this.clearTimer();
      this.getRandomAdv();
      this.countdown();
    },
  },
  methods: {
    // 随机获取广告
    getRandomAdv() {
      this.advsList = [];
      let advs = getAdItem(AdType.FREE_VIDEO);
      if (advs.length > 0) {
        let index = Math.floor(Math.random() * advs.length);
        this.advsList.push(advs[index]);
      } else {
        this.isShowAdv = false;
      }
    },
    // 倒计时
    countdown() {
      if (this.advsList.length == 0) return;
      this.timer = this.advsList[0]?.duration > 0 ? this.advsList[0]?.duration : 0;
      this.countdowner = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(this.countdowner);
          this.countdowner = null;
        }
      }, 1000);
    },
    // 清除计时器
    clearTimer() {
      clearInterval(this.countdowner);
      this.countdowner = null;
    },
    async getVideoInfo() {
      try {
        let req = {
          videoID: this.$route.query.id,
          sectionId: this.$route.query.sectionId || undefined,
        };
        let res = await this.$Api(getVidInfo, req);
        if (res.code == 200) {
          this.videoInfo = res.data;
          this.getCommentList();
        }
      } finally {
        this.isLoading = false;
      }
    },
    // 获取评论
    async getCommentList() {
      let req = {
        objID: this.videoInfo.id,
        curTime: getBeijinTime(),
        pageNumber: '1',
        pageSize: '10',
      };
      let res = await this.$Api(queryCommentList, req);
      if (res.code === 200) {
        this.commenTotal = res.data.total;
      }
    },
    addCommenTotal() {
      this.commenTotal++;
    },
    onClickTab() {
      // Toast("只有会员才能评论哦～");
      this.$bus.$emit('promptPopupEvent', '您还不是VIP无法查看神评论');
    },
    openShare() {
      this.$store.commit('user/UPDATE_SHARE', {
        show: true,
        info: { ...this.videoInfo },
      });
    },
    //收藏
    async collecte() {
      let isCollect = this.videoInfo?.vidStatus?.hasCollected;
      let id = this.videoInfo?.id;
      let req = {
        type: 'movie',
        objIds: [id],
        isCollect: !isCollect,
      };
      try {
        let res = await this.$Api(careCollectVideo, req);
        if (res.code === 200) {
          this.videoInfo.vidStatus.hasCollected = !isCollect;
          if (isCollect) {
            Toast('取消点赞');
            this.videoInfo.forwardCount--;
          } else {
            Toast('点赞成功');
            this.videoInfo.forwardCount++;
          }
          return;
        }
        Toast('操作失败');
      } catch (e) {
        Toast('操作失败');
      }
    },
  },
};
</script>
<style scoped lang="scss">
.video_tab,
/deep/ .van-tabs__content,
/deep/ .van-tab__pane {
  height: 100%;
}

.content_all {
  height: 100%;
  overflow: hidden;
}
.horizontalVideo_box {
  height: 100%;
  .phead-left {
    position: absolute;
    top: 10px;
    left: 16px;
    z-index: 3001;
    pointer-events: auto;
    svg {
      font-size: 36px;
    }
  }
}
.horizontalVideo_warp {
  height: 100%;

  .h_video {
    width: 100%;
    height: 210px;
    background: #000000;
    .videoAdv {
      position: relative;
      height: 210px;
      color: #ffffff;
      .timer_close {
        font-size: 12px;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        background: #00000050;
        padding: 4px 10px;
        border-radius: 40px;
        span {
          color: #ff7f0f;
        }
      }
      .adv {
        height: 100%;
        /deep/ .swiper-container {
          height: 210px;
        }
        /deep/ img {
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 254px);
    position: relative;
    .menu {
      position: absolute;
      top: 0;
      right: 16px;
      display: flex;
      align-items: center;
      height: 44px;
      .menu_item {
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 12px;

        > svg {
          font-size: 20px;
          padding-right: 2px;
        }
      }
    }
    .video_tab {
      /deep/ .van-info {
        position: absolute;
        top: 11px;
        height: 44px;
        right: -10px;
        color: #7c879f;
        border: none;
        background: transparent;
        border-radius: 0;
        font-size: 11px;
        display: flex;
        align-items: center;
      }

      /deep/ .van-tab {
        font-size: 16px;
        flex: unset;
        padding: 0 10px;
      }

      /deep/ .van-tabs__line {
        bottom: 18px;
        width: 18px;
        height: 3px;
        border-radius: 2px;
        background: #ff678f;
      }
      /deep/ .van-tabs__nav {
        padding-left: 10px;
      }
    }
  }
}
</style>
