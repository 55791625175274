<template>
  <div class="discuss_warp">
    <div class="discuss" @click="clear">
      <Comment
        :videoId="videoId"
        :isInputShow="false"
        @close="isSendShow = false"
        :isPopShow="true"
        :customizeReplay="true"
        @parReply="parReply"
        :parData="data"
      />
    </div>
    <div class="common_input_box">
      <input
        type="text"
        :placeholder="placeholder"
        v-model="commentValue"
        :class="commentValue ? 'vant80' : 'vant100'"
        class="sendInout"
        maxlength="120"
        ref="commentInp"
      />
      <div class="send" v-if="commentValue.length > 0" @click="sendMessage">发送</div>
    </div>
  </div>
</template>
<script>
import Comment from '@/components/Comment';
import { Field, Toast } from 'vant';
import { commentSend } from '@/api/user';

export default {
  props: {
    videoId: {
      type: String,
      required: true,
      default: '',
    },
    addCommenTotal: {
      type: Function,
      default: null,
    },
  },
  components: {
    Comment,
    [Field.name]: Field,
  },
  data() {
    return {
      commentValue: '',
      data: {
        data: {},
        isReply: false,
      },
      placeholder: '发一条评论',
      isReply: false,
      replyParams: {},
    };
  },
  methods: {
    async sendMessage() {
      let req = {
        content: this.commentValue.trim(),
      };
      if (!this.isReply) {
        req = Object.assign(req, {
          objID: this.videoId,
          level: 1,
        });
      } else {
        req = Object.assign(req, this.replyParams);
      }
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(commentSend, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          this.addCommenTotal();
          //一级评论
          if (!this.isReply) {
            this.data.data = res.data;
            Toast('评论成功');
            this.commentValue = '';
            return;
          }
          //二级评论
          this.data = {
            data: res.data,
            isReply: true,
          };
          Toast('评论成功');
          setTimeout(() => {
            this.clear();
          }, 0);
          return;
        }
        Toast(res.tip || '评论失败');
      } catch (error) {
        Toast('评论失败');
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    clear() {
      this.commentValue = '';
      this.isReply = false;
      this.placeholder = '留下您精彩的评论吧～';
      this.data.isReply = false;
      this.data.data = {};
    },
    parReply(data) {
      if (this.$refs.commentInp) {
        this.$nextTick(() => {
          this.$refs.commentInp.focus();
        });
      }
      this.placeholder = `回复：${data.data.userName}`;
      this.replyParams = {
        objID: data.data.objID,
        level: 2,
        userID: data.data.userID,
        cid: data.data.id,
      };
      this.isReply = true;
    },
  },
};
</script>
<style scoped lang="scss">
.discuss_warp {
  height: 100%;
  //overflow-y: auto;
  background: #ffffff;
  .discuss {
    height: calc(100% - 105px);
    padding: 0 16px;
    box-sizing: border-box;
  }

  .common_input_box {
    position: absolute;
    bottom: 44px;
    width: 100%;
    height: 66px;
    background: #f2f5f8;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;

    /deep/ .van-cell::after {
      border: none;
    }

    ::placeholder {
      color: #999999;
    }

    // /deep/ .van-field__control {
    //   font-size: 16px;
    // }

    input {
      background: #1f1f1f;
      border-radius: 18.5px;
      margin: 0 16px;
      height: 37px;
      padding: 0 10px;
      font-size: 16px;
      background: #ffffff;
      border: none;
      outline: none !important;
      display: block;
      border-radius: 18.5px;
      transition: all 0.5s linear;
    }

    .send {
      width: 60px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background-color: #ffffff;
      border-radius: 20px;
      margin-right: 12px;
      color: #ff678f;

      svg {
        font-size: 20px;
      }
    }

    .vant100 {
      width: 100%;
      padding: 0 10px;
    }

    .vant80 {
      width: 80%;
      padding: 0 10px;
    }
  }
}
</style>
