<template>
  <div class="introduction_warp" ref="introduction_warp">
    <div class="header">
      <!-- <BolHeader class="bolHeader" :info="videoInfo.publisher"/> -->

      <div class="post-top">
        <div class="post-top-left flex-center-center">
          <div class="post-avatar" @click.stop="jumpUserPage" :class="{ isCertifica: videoInfo.publisher.merchantUser == 1 }">
            <ImgDecypt :src="videoInfo.publisher.portrait" round />
            <div class="certification" v-if="videoInfo.publisher.superUser == 1">
              <Vlogo :vid="videoInfo.id" />
            </div>
            <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="videoInfo.publisher.merchantUser == 1" />
            <!-- <svg-icon class="certification" iconClass="certification"
                      v-if="videoInfo.publisher.superUser == 1"></svg-icon> -->
          </div>
          <div class="post-info">
            <div class="username_box ellipsis">
              <span
                :class="{
                  vipName: videoInfo.publisher.isVip && videoInfo.publisher.vipLevel > 0,
                }"
                @click.stop="jumpUserPage"
              >
                {{ videoInfo.publisher.name }}
              </span>
              <svg-icon class="user_icon" iconClass="crown" v-if="videoInfo.publisher.isVip && videoInfo.publisher.vipLevel > 0"></svg-icon>
              <div class="user_icon" v-for="icon in userIcons" :key="icon.number">
                <ImgDecypt :src="icon.imgUrl" v-if="icon.isExpire" />
              </div>
            </div>
            <!--显示地区-->
            <div class="post_Introduction flex">
              <div class="location ellipsis" @click.stop="openCity">
                <svg-icon icon-class="location" />
                <span v-if="videoInfo.location">
                  {{ videoInfo.location.city }}
                </span>
              </div>
              <div class="pub_time">{{ videoInfo.reviewAt | dateago }}</div>
            </div>
          </div>
        </div>
        <div
          class="post-top-right"
          @click.stop="focus"
          v-if="!videoInfo.publisher.hasFollowed && videoInfo.publisher.uid != $store.getters.userInfo.uid"
        >
          关注
        </div>
      </div>

      <h2 class="videoTitle">{{ videoInfo.title }}</h2>
      <div class="tags">
        <div class="tag_item ellipsis" v-for="tag in videoInfo.tags" :key="tag.id" @click="openTag(tag)">
          <svg-icon icon-class="tag"></svg-icon>
          {{ tag.name }}
        </div>
      </div>
      <!-- <div class="menu">
        <div class="menu_item" @click="openShare">
          <svg-icon icon-class="share_video" />
          <span>赚钱</span>
        </div>
        <div class="menu_item" @click="collecte">
          <svg-icon
            :icon-class="
              videoInfo.vidStatus && videoInfo.vidStatus.hasCollected
                ? 'xined'
                : 'v_xin'
            "
          />
          <span>{{ (videoInfo.forwardCount || '点赞') | watchCount }}</span>
        </div>
      </div> -->
    </div>
    <!--  广告banner -->
    <AdvSwiper :advList="advList" class="adv" height="111px" />
    <!--视频列表-->
    <div class="video_list">
      <div class="title_box">
        <h2 class="title">精品推荐</h2>
      </div>
      <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad" @onRefresh="onRefresh" :isNoData="isNoData">
        <MovieList :data-list="list" class="v_list" :isFromVideoPlay="true" />
      </PullRefresh>
    </div>
  </div>
</template>
<script>
import MovieList from '@/components/MovieList';
// import BolHeader from "@/components/Post/header"
import Vlogo from '@/components/Vlogo';
import { queryCollection } from '@/api/video';
import PullRefresh from '@/components/PullRefresh';
import AdvSwiper from '@/components/AdvSwiper';
import ImgDecypt from '@/components/ImgDecypt';
import { cancleZan, careCollectVideo, zan, careOrcancle } from '@/api/user';
import { AdType, getAdItem } from '@/utils/getConfig';
import { Toast } from 'vant';
import { setSessionItem } from '@/utils/longStorage';
import { getMedal } from '@/utils/getConfig';

export default {
  props: {
    videoInfo: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    getVideoInfo: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      advList: [], // 广告banner列表
    };
  },
  components: {
    MovieList,
    PullRefresh,
    AdvSwiper,
    // BolHeader,
    ImgDecypt,
    Vlogo,
  },
  watch: {
    $route(to, from) {
      if (to.path == from.path) {
        this.getVideoInfo();
      }
    },
  },
  computed: {
    userIcons() {
      return getMedal(this.videoInfo?.publisher?.awardsExpire || []) || [];
    },
  },
  created() {
    this.advList = getAdItem(AdType.COMMUNITY_DETAILS);
    if (this.videoInfo.id) {
      this.getRecomList();
    }
  },
  methods: {
    // 获取推荐视频列表
    async getRecomList(type) {
      let req = {
        uid: this.videoInfo.publisher.uid,
        pageSize: String(this.pageSize),
        pageNumber: String(this.pageNumber),
        type: 'new',
        newsType: 'MOVIE',
      };
      let res = await this.$Api(queryCollection, req);
      this.loading = false;
      this.refreshing = false;
      if (res.code === 200) {
        let list = res.data.list || [];
        if (type === 'refresh') {
          this.list = list;
        } else {
          this.list = this.list.concat(list);
        }
        if (this.pageNumber == 1 && this.list.length == 0) {
          this.isNoData = true;
        }
        if (!res.data.hasNext) {
          this.finished = true;
          return;
        }
      }
    },
    openShare() {
      this.$store.commit('user/UPDATE_SHARE', {
        show: true,
        info: { ...this.videoInfo },
      });
    },
    //前往标签页
    openTag(item) {
      let fromPath = this.$route.query.fromPath;
      if (fromPath == 'tags') {
        setSessionItem('tagId', item.id);
        this.$router.go(-1);
        return;
      }
      this.$router.push({ path: '/tags', query: { id: item.id } });
    },
    //点赞
    async like() {
      let hasLiked = this.videoInfo?.vidStatus?.hasLiked;
      let id = this.videoInfo?.id;
      let req = {
        type: 'video',
        objID: id,
      };
      try {
        if (hasLiked) {
          let res = await this.$Api(cancleZan, req);
          if (res.code === 200) {
            this.videoInfo.vidStatus.hasLiked = false;
            this.videoInfo.likeCount--;
            Toast('取消点赞');
            return;
          }
          Toast(res.tip || '取消失败');
        } else {
          let res = await this.$Api(zan, req);
          if (res.code === 200) {
            this.videoInfo.vidStatus.hasLiked = true;
            this.videoInfo.likeCount++;
            Toast('点赞成功');
            return;
          }
          Toast(res.tip || '点赞失败');
        }
      } catch (e) {
        Toast('操作失败');
      }
    },
    //收藏
    async collecte() {
      let isCollect = this.videoInfo?.vidStatus?.hasCollected;
      let id = this.videoInfo?.id;
      let req = {
        type: 'movie',
        objIds: [id],
        isCollect: !isCollect,
      };
      try {
        let res = await this.$Api(careCollectVideo, req);
        if (res.code === 200) {
          this.videoInfo.vidStatus.hasCollected = !isCollect;
          if (isCollect) {
            Toast('取消点赞');
            this.videoInfo.forwardCount--;
          } else {
            Toast('点赞成功');
            this.videoInfo.forwardCount++;
          }
          return;
        }
        Toast('操作失败');
      } catch (e) {
        Toast('操作失败');
      }
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.getRecomList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.getRecomList('refresh');
    },
    // 点击头像跳转用户主页
    jumpUserPage() {
      // :to="{path:'/userHomePage',query:{uid:postInfo.publisher.uid, fromPath: $route.path}}"
      if (this.$route.path == '/userHomePage' && this.$route.query != this.videoInfo.publisher.uid) {
        this.$router.replace({
          path: '/userHomePage',
          query: {
            uid: this.videoInfo.publisher.uid,
            fromPath: this.$route.path,
          },
        });
      } else {
        this.$router.push({
          path: '/userHomePage',
          query: {
            uid: this.videoInfo.publisher.uid,
            fromPath: this.$route.path,
          },
        });
      }
    },
    //关注
    async focus() {
      if (this.videoInfo?.publisher.hasFollowed) {
        return;
      }
      let uid = this.videoInfo?.publisher?.uid;
      let req = {
        followUID: parseInt(uid),
        isFollow: true,
      };
      if (!uid) {
        Toast('当前用户信息错误');
        return;
      }
      try {
        let res = await this.$Api(careOrcancle, req);
        if (res.code === 200) {
          this.videoInfo.publisher.hasFollowed = true;
          this.$emit('followedAllSuccess', uid);
          Toast('关注成功');
          return;
        }
        Toast(res.tip || '关注失败');
      } catch (e) {
        Toast('关注失败');
      }
    },
    //前往城市页
    openCity() {
      let id = this.videoInfo?.location?.id;
      let city = this.videoInfo?.location?.city;
      let fromPath = this.$route.query.fromPath;
      if (fromPath == 'citys') {
        this.$router.go(-1);
        return;
      }
      this.$router.push({ path: '/citys', query: { id: id, city: city } });
    },
  },
};
</script>
<style scoped lang="scss">
.introduction_warp {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #ffffff;

  .post-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 5px;

    .post-top-left {
      .post-avatar {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        margin-right: 8px;
        position: relative;

        .certification {
          width: 16.1px;
          height: 16.2px;
          position: absolute;
          right: 0;
          bottom: 0;
          overflow: hidden;
          border-radius: 50%;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
        }
        .certificaBorder {
          position: absolute;
          top: -8.2px;
          width: 100%;
          font-size: 25px;
          z-index: 1;
        }
      }

      .isCertifica {
        border-radius: 50%;
        border: 1.5px solid #d82d0b;
      }

      .post-info {
        width: 200px;
        display: flex;
        flex-direction: column;

        .username_box {
          display: flex;
          align-items: center;

          .user_icon {
            width: 17px;
            height: 17px;
            padding-left: 5px;

            /deep/ .vanImage {
              background: #00000000 !important;
            }
          }

          span {
            font-size: 18px;
            letter-spacing: -0.41px;
            color: #333333;
          }
          .vipName {
            color: #ff678f;
          }
        }

        .fan {
          padding-top: 4px;

          .fan_text {
            font-size: 13px;
            color: #7c879f;
          }
        }

        .post_Introduction {
          font-size: 15px;
          letter-spacing: -0.08px;
          color: #7c879f;
          padding-top: 4px;

          svg {
            font-size: 16px;
            padding-right: 2px;
          }

          .location {
            max-width: 150px;
            padding-right: 16px;
            span {
              color: #666666;
            }
          }
          .pub_time {
            color: #666666;
          }
        }
      }
    }

    .post-top-right {
      display: inline-block;
      width: 56px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      color: #ff82a2;
      border: 1px solid #ff82a2;
      border-radius: 20px;
      font-size: 14px;

      svg {
        font-size: 13px;
      }
    }
  }
  .adv {
    padding: 0 0 16px 0;
    /deep/ .swiper-container {
      height: 136px;
    }
    /deep/ img {
      height: 100%;
    }
  }

  .header {
    padding: 0 20px;
    box-sizing: border-box;
    .videoTitle {
      // width: 100%;
      max-height: 68px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }

    .bolHeader {
      margin: 12px 0 8px;
    }

    h2 {
      padding: 7px 0;
      font-size: 17px;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      color: #666666;

      .tag_item {
        padding: 2px 13px;
        font-size: 12px;
        background: #f2f3f6;
        margin-bottom: 10px;
        margin-right: 10px;
        border-radius: 20px;
      }
      svg {
        font-size: 12px;
        padding-right: 4px;
      }
    }

    .menu {
      padding-top: 5px;
      padding-bottom: 19px;
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;
      color: #a4aec1;
      font-size: 11px;

      .menu_item {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          padding-top: 5px;
        }

        > svg {
          font-size: 29px;
        }
      }
    }
  }

  .video_list {
    .title_box {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
    }
    .title {
      font-size: 17px;
      height: 24px;
      display: flex;
      align-items: center;
    }
    .more {
      font-size: 14px;
      color: #999999;
    }
    .v_list {
      padding: 14px 16px;
    }
  }
}
</style>
