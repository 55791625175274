<template>
  <div class="video_warp">
    <div class="video_box">
      <div id="xgVideo" ref="dplayer" playsinline webkit-playsinline></div>
      <!-- 视频状态 -->
      <div v-show="videoCanplay">
        <div v-if="videoInfo.watch.isFreeWatch"></div>
        <div class="vip-info" v-else-if="!isVip && wCount > 0 && videoInfo.originCoins == 0">免费视频剩余 {{ wCount }} 次</div>
        <div class="vip-info buied" v-else-if="!isVip && videoInfo.originCoins == 0 && isCan">每日免费视频</div>
        <div class="vip-info" v-else-if="!isVip && !isCan && videoInfo.originCoins == 0" @click="payVip">
          <div class="fl vip">
            <svg-icon icon-class="play2" />
            <span>观看完整版</span>
          </div>
        </div>
        <div class="vip-info" v-else-if="videoInfo.originCoins > 0 && !videoInfo.vidStatus.hasPaid" @click="payGold">
          <div class="fl gold">
            <svg-icon icon-class="gold" />
            <span>观看完整版</span>
          </div>
        </div>
      </div>
    </div>
    <!--金币购买弹窗-->
    <GoldBuy :video-info="videoInfo" :show="isGoldbuyShow" @close="isGoldbuyShow = false" :isVip="isVip" @succcess="succcess" :isMovies="true" />
    <!-- 会员购买弹窗-->
    <VipCardBuy :show="isVipCardbuyShow" @close="isVipCardbuyShow = false" />
    <VipBuy :show="isVipbuyShow" @close="isVipbuyShow = false" />
  </div>
</template>
<script>
import HlsJSPlayer from 'xgplayer-hls.js';
import { mapGetters } from 'vuex';
import { getLocalItem } from '@/utils/longStorage';
import GoldBuy from '@/components/GoldBuy';
import VipBuy from '@/components/VipBuy';
import VipCardBuy from '@/components/VipCardBuy';
import { Toast } from 'vant';
import { getPlayVideo, playCount } from '@/api/video';
import { addVideoHistory } from '@/utils';

export default {
  props: {
    videoInfo: {
      type: Object,
      required: true,
      default: () => {
        return {
          sourceURL: '',
        };
      },
    },
  },
  components: {
    GoldBuy,
    VipBuy,
    VipCardBuy,
  },
  data() {
    return {
      player: null,
      wCount: 0, //免费观看次数
      isCan: false, //是否能观看 true 能观看，false不能观看 (只有会员视频才能判断)
      isGoldbuyShow: false,
      isVipbuyShow: false,
      isVipCardbuyShow: false,
      nowDate: new Date(),
      playTimer: null,
      playTime: 0,
      via: 0,
      isFreeWatch: '',
      vipPopList: [],
      isReport: false,
      videoCanplay: false, //视频是否可以播放
    };
  },
  computed: {
    ...mapGetters({
      baseUrl: 'baseUrl',
      userInfo: 'userInfo',
      isVip: 'isVip',
    }),
  },
  mounted() {
    if (this.videoInfo.id) {
      this.isFreeWatch = this.videoInfo.watch.isFreeWatch;
      this.init();
    }
  },
  created() {
    if (Object.prototype.toString.call(this.$store.state.app.appConfig) === '[object String]') {
      this.vipPopList = JSON.parse(this.$store.state.app.appConfig)?.pops ?? [];
    } else {
      this.vipPopList = this.$store.state.app.appConfig?.pops ?? [];
    }
    if (this.videoInfo.id) {
      this.playCount();
      this.$store.dispatch('user/getWallet');
    }
    if (this.$route.query.via) {
      this.via = parseInt(this.$route.query.via);
    }
  },
  methods: {
    payGold() {
      this.isGoldbuyShow = true;
    },
    payVip() {
      if (this.vipPopList.length > 0) {
        this.isVipCardbuyShow = true;
      } else {
        this.isVipbuyShow = true;
      }
    },
    async playCount() {
      let res = await this.$Api(playCount, { vid: this.videoInfo.id });
      if (res.code === 200) {
        this.isCan = res.data.isCan;
        this.wCount = res.data.watchCount;
      }
    },
    init() {
      const player = (this.player = new HlsJSPlayer({
        id: 'xgVideo',
        url: this.getPath(this.videoInfo.sourceURL),
        fluid: true,
        lang: 'zh-cn',
        playsinline: true,
        autoplay: true,
        videoInit: true,
        controls: true,
        playbackRate: [0.5, 1, 1.5, 2],
        defaultPlaybackRate: 1,
        ignores: ['volume'],
        closeVideoClick: true,
        closePlayVideoFocus: false,
        closeVideoStopPropagation: true,
        closeVideoPreventDefault: false,
        closeInactive: false,
      }));

      player.on('playing', () => {
        this.playing();
        this.$store.commit('video/SET_CURRENT_VIDEO_OBJ', this.videoInfo);
      });
      player.on('pause', () => {
        this.pauses();
      });
      player.on('waiting', () => {
        this.pauses();
      });
      player.on('canplay', () => {
        this.videoCanplay = true;
      });
      player.on('timeupdate', () => {
        try {
          let totalTime = player.video.duration;
          let currentTime = player.video.currentTime;
          let report = 15;
          if (totalTime > 300) {
            report = totalTime * 0.1;
          }
          if (totalTime < 15) {
            report = totalTime;
          }
          let reportData = {
            dataType: 1,
            videoId: this.videoInfo.id,
            videoTitle: this.videoInfo.title,
          };

          if (!this.playCheck() && player.hasStart) {
            player.pause();
            if (this.videoInfo.originCoins == 0) {
              this.payVip();
            } else {
              this.isGoldbuyShow = true;
            }
            if (!this.isReport) {
              this.$bus.$emit('dataBuriedEvent', reportData);
              this.isReport = true;
            }
          } else {
            if (!this.isReport && currentTime >= report) {
              this.$bus.$emit('dataBuriedEvent', reportData);
              this.isReport = true;
            }
          }
        } catch (e) {
          player.pause();
          Toast('播放异常，请重新进入');
        }
      });
    },
    //当视频滑走的时候调用 用作统计
    async getPlayInfo() {
      let req = {
        publisher: this.videoInfo.publisher.uid,
        videoID: this.videoInfo.id,
        beginTime: this.nowDate,
        endTime: new Date(),
        longer: this.playTime,
        via: this.via || 1,
        playWay: this.isFreeWatch ? 0 : this.isCan ? (this.wCount ? 0 : 1) : 2, // 0-免费 1-付费 2-试看
      };
      if (this.videoInfo.tags && this.videoInfo.tags.length > 0) {
        req.tagID = this.videoInfo.tags[0].id;
      }
      this.$Api(getPlayVideo, req);
      clearInterval(this.playTimer);
      this.playTimer = null;
      this.playTime = 0;
    },
    playing() {
      if (!this.playTimer) {
        this.playTimer = setInterval(() => {
          this.playTime++;
        }, 1000);
      }
    },
    pauses() {
      clearInterval(this.playTimer);
      this.playTimer = null;
    },
    getPath(u) {
      const token = getLocalItem('token');
      const newUrl = this.baseUrl + 'vid/h5/m3u8/' + u + '?token=' + token;
      return newUrl;
    },
    //检测视频是否能播放
    playCheck() {
      try {
        if (this.isFreeWatch) {
          return true;
        }
        if (this.player.video?.currentTime < this.videoInfo?.freeTime) {
          return true;
        }
        if (this.videoInfo?.originCoins === 0) {
          //会员视频
          if (this.isCan) {
            return true;
          }
          return false;
        } else {
          // 金币视频
          if (this.videoInfo.vidStatus.hasPaid) {
            return true;
          }
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    //视频购买成功
    succcess() {
      this.isGoldbuyShow = false;
      this.videoInfo.vidStatus.hasPaid = true;
      this.player.play();
      this.$store.dispatch('user/getWallet');
      Toast('购买成功');
    },
  },
  beforeDestroy() {
    addVideoHistory(this.videoInfo);
    if (this.player) {
      this.getPlayInfo();
      this.player.destroy(true); //销毁播放器
    }
    this.player = null;
  },
};
</script>
<style lang="scss" scoped>
.video_warp {
  position: relative;
  background: #000000;
  height: 100%;
  /deep/ .vip_buy_pop {
    position: fixed;
  }

  .video_box {
    width: 100%;
    height: 100%;
    background: black;
    position: relative;

    .vip-info {
      position: absolute;
      top: 5px;
      right: 10px;
      z-index: 0;
      font-size: 12px;
      color: #ffffff;
      display: inline-block;
      padding: 3px 14px;
      background: #ff678f;
      border-radius: 18px;
      display: flex;
      align-items: center;
      pointer-events: auto;

      .gold {
        > svg {
          padding-right: 4px;
          font-size: 15px;
        }
      }

      .vip {
        > svg {
          font-size: 15px;
          padding-right: 3px;
        }
      }
    }

    .buied {
      background: #ff5c8475;
      pointer-events: none;
    }
    #xgVideo {
      width: 100%;
      height: 100%;
      background: #000000;
      /deep/ .xgplayer-progress-played {
        background: #ff678f;
      }
      /deep/ .xgplayer-enter-spinner {
        width: 50px;
        height: 50px;
        z-index: 1;
      }
    }
  }
}
</style>
